<template>
  <div>
    <div class="padding_top"></div>

    <b-container v-if="event">
      <b-row align-h="center" class="bk_card">
        <b-col cols="12" md="5">
          <div class="reg_img__wrapper">
            <img
              v-if="event.img"
              class="reg_img__img"
              :src="$store.state.storageUrl + '/app/events/' + event.img"
            />

            <img
              v-else
              class="event-img__img"
              :src="$store.state.storageUrl + '/app/defaults/darkLogo.jpg'"
            />
          </div>
        </b-col>

        <!-- About event -->
        <b-col cols="12" md="7" class="about-event_content">
          <b-row align-h="center">
            <b-col cols="12">
              <h2 class="registrations_title" v-html="event.title"></h2>
            </b-col>
          </b-row>

          <b-row align-h="center" class="event-block">
            <b-col cols="12">
              <h5 class="subtitle-event-modal">О мероприятии:</h5>

              <p class="about-event_text">
                <span class="desc_title">Когда: </span
                >{{ formatDateTime(event.date_time, "time") }}
                {{ formatDateTime(event.date_time, "date") }}
              </p>

              <p class="about-event_text">
                <span class="desc_title">Где: </span>{{ event.location }}
              </p>

              <p class="about-event_text">
                <span class="desc_title">Всего мест: </span
                >{{ event.count_seats }}
              </p>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row align-h="center">
        <b-col cols="12" md="8" v-if="regs">
          <b-row align-h="center">
            <p class="users__user_score count_users regs_count">
              Количество регистраций
              <span class="accent">{{ regs.length }}</span>
            </p>
          </b-row>

          <b-row
            class="users__user_line"
            v-for="(reg, index) in regs"
            :key="index"
          >
            <b-col cols="2" md="auto">
              <div class="users__user_img_wrapper">
                <img
                  v-if="reg.user.img"
                  class="users__user_img"
                  :src="
                    $store.state.storageUrl + '/app/avatars/' + reg.user.img
                  "
                />
                <img
                  v-else
                  class="users__user_img"
                  :src="$store.state.storageUrl + '/app/defaults/darkLogo.jpg'"
                />
              </div>
            </b-col>

            <b-col cols="10">
              <div class="users__user_info">
                <p class="users__user_name">
                  {{ reg.user.name }} {{ reg.user.last_name }}
                </p>

                <p
                  class="users__user_description"
                  v-html="reg.user.about_user"
                ></p>

                <p class="users__user_score">
                  Оплачено:
                  <span class="accent">{{
                    reg.status ? reg.price.value + "₽" : "Не оплачена"
                  }}</span>
                </p>

                <p class="users__user_score">
                  Телефон: <br />
                  <a :href="'callto:' + reg.user.phone" class="accent">{{
                    reg.user.phone ? reg.user.phone : "---"
                  }}</a>
                </p>

                <p class="users__user_score">
                  Email:<br />
                  <a :href="'mailto:' + reg.user.email" class="accent">{{
                    reg.user.email ? reg.user.email : "---"
                  }}</a>
                </p>
              </div>
            </b-col>

            <b-col cols="12">
              <b-row>
                <b-col cols="6">
                  <p class="reg_date">
                    <span class="desc_title">Дата создания: </span> <br />
                    {{ formatDateTime(reg.created_at, "spec") }}
                  </p>
                </b-col>

                <b-col cols="6">
                  <p class="reg_date">
                    <span class="desc_title">Дата изменения: </span><br />
                    {{ formatDateTime(reg.updated_at, "spec") }}
                  </p>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  data: () => {
    return {
      event: null,
      regs: null,
    };
  },
  methods: {
    // DateTime format
    formatDateTime(dateTime, type) {
      moment.locale("ru");
      var result;

      switch (type) {
        case "date":
          result = moment(String(dateTime)).format("LT");
          break;

        case "time":
          result = moment(String(dateTime)).format("DD MMMM");
          break;

        case "spec":
          result = moment(String(dateTime)).format("DD.MM.YYYY H:m:s");
          break;
      }

      return result;
    },
  },
  async mounted() {
    let id = this.$route.query.event;

    try {
      let data = await axios.get("registrations/" + id).then((res) => {
        return res.data;
      });

      this.event = data.event;
      this.regs = data.registrations;
    } catch (e) {
      console.error(e);
    }
  },
};
</script>

<style lang="scss">
.registrations_title {
  padding-top: 30px;
  font-size: 24px;
}
.reg_img__wrapper {
  height: 300px;
  width: 100%;
  position: relative;
  overflow: hidden;
}
.reg_img__img {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.reg_date {
  font-size: 12px;
  color: $light_gray__medium;
  margin: 0;
}
.regs_count {
  padding-top: 60px;
}

// Old styles
.modal-header {
  border-bottom: 0px;
}
.modal-header .close {
  opacity: 100%;
  color: $accent;
  border-width: 0px;
  outline: none;
  box-shadow: 1px 0 0 0 rgba($black, 1);
  text-decoration: none;
  background-color: rgba($black, 0);
}
#aboutEvent {
  color: $white;
}
.about-event_content {
  padding-bottom: 30px;
}
.reg_event {
  padding-top: 30px;
  padding-bottom: 30px;

  border-color: $light_gray__dark;
  border-style: dashed;
  border-width: 2px;
}
.price_content {
  padding-bottom: 60px;
}
.price {
  padding-top: 30px;
  text-align: center;
  font-weight: 300;
}

input,
select,
.form-control,
.custom-select {
  border-radius: 0px;
  border: 0px;

  box-shadow: none;
  outline: none;

  height: 46px;

  transition: linear 200ms;
}

.custom-select:focus,
.form-control:focus {
  box-shadow: none;
  outline: none;

  outline-width: 1px;
  outline-style: solid;
  outline-color: $accent;

  box-shadow: 0px 0px 14px 0px rgba(255, 68, 51, 0.7);

  transition: linear 200ms;
}
.desc_title {
  color: $light_gray__dark;
  font-weight: 300;
  font-size: 14px;
}

.title-event-modal {
  font-weight: 700;
  font-size: 34px;
  padding-bottom: 15px;
  margin: 0;
}
.spicker {
  font-weight: 600;
  font-size: 18px;
  margin: 0;
}
.subtitle-event-modal {
  font-size: 16px;
  color: $light_gray__dark;
  font-weight: 300;
  margin: 0;
  padding-bottom: 5px;
}
.speacker-block {
  padding-bottom: 15px;
}
.event-block {
  padding-bottom: 15px;
}
.about-event_text {
  margin: 0;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
}

.no-events {
  min-height: 80vh;
}

// users old
.users__user_line {
  margin-bottom: 15px;
  background-color: $black;
  padding: 15px;
}

.users__user_img_wrapper {
  position: relative;
  height: 60px;
  width: 60px;
  overflow: hidden;
  border-radius: 100%;
  border: 2px solid $gray;
}
.users__user_img {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.users__user_info {
  padding-left: 15px;
}
.users__user_name {
  font-size: 18px;
  font-weight: 600;
  color: $white;
  margin: 0;
}
.users__user_description {
  font-size: 16px;
  font-weight: 400;
  color: $light_gray__medium;
  margin: 0;
}
.users__user_score {
  font-size: 14px;
  font-weight: 700;
  color: $light_gray__medium;
  margin: 0;
}
.users__user_score.count_users {
  padding-bottom: 15px;
  font-weight: 600;
  font-size: 18px;
}
</style>
